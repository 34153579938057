import * as ActionTypes from './ActionTypes'
import axios from 'axios';
const wApi = async (params) => {
  const res = await axios({
    method: 'post',
    url:"https://api.kankerroadways.com/followup_live_api.php",
    data:params,
    crossDomain: true,
    headers: { 'Content-type': 'multipart/form-data' }
   })
  .catch(error => {
    console.log(error)
  });
  return res.data; 
};

export const taskDelete=(hook,formdata)=>{
  return(dispatch,getState)=>{
    wApi({hook:hook,formdata:formdata}).then(response=>{
      dispatch({
        type:ActionTypes.TASK_DELETE,
        payload:response
      })
    }).catch(err=>{})
  }
}

export const userAdminLogin=(hook,formdata)=>{
  return(dispatch,getState)=>{
    dispatch({
        type:ActionTypes.USER_ADMIN_LOGIN,
        payload:formdata
      })
  }
}

/****************************************  LOGOUT  ******************************************/
export const logoutCheck=(hook,formdata)=>{
  return(dispatch,getState)=>{
    dispatch({
        type:ActionTypes.LOGOUT_CHECK,
        payload:''
      })
  }
}

/* USER_ADMIN_LOGIN_CHECK ******************************************/
export const userAdminLoginCheck=(hook,formdata)=>{
  return(dispatch,getState)=>{
    wApi({hook:hook,formdata:formdata}).then(response=>{
      dispatch({
        type:ActionTypes.USER_ADMIN_LOGIN_CHECK,
        payload:response
      })
    }).catch(err=>{})
  }
}

/* UPDATE_USER_LOGIN ******************************************/
export const updateUserLogin=(hook,formdata)=>{
  return(dispatch,getState)=>{
    wApi({hook:hook,formdata:formdata}).then(response=>{
      dispatch({
        type:ActionTypes.UPDATE_USER_LOGIN,
        payload:response
      })
    }).catch(err=>{})
  }
}

/* SEARCH_TASK_DETAIL ******************************************/
export const updateTaskDetail=(hook,formdata)=>{
  return(dispatch,getState)=>{
    wApi({hook:hook,formdata:formdata}).then(response=>{
      dispatch({
        type:ActionTypes.UPDATE_TASK_DETAIL,
        payload:response
      })
    }).catch(err=>{})
  }
}


/* SEARCH_TASK_DETAIL ******************************************/
export const searchTaskDetail=(hook,formdata)=>{
  return(dispatch,getState)=>{
    wApi({hook:hook,formdata:formdata}).then(response=>{
      dispatch({
        type:ActionTypes.SEARCH_TASK_DETAIL,
        payload:response
      })
    }).catch(err=>{})
  }
}



/*  USER_SUPPORT_TICKET_TIMELINE ******************************************/
export const adminViewSupportTicketList=(hook,formdata)=>{
  return(dispatch,getState)=>{
    wApi({hook:hook,formdata:formdata}).then(response=>{
      dispatch({
        type:ActionTypes.ADMIN_SUPPORT_TICKET_LIST,
        payload:response
      })
    }).catch(err=>{})
  }
}



/*  USER_SUPPORT_TICKET_TIMELINE ******************************************/
export const userSupportTicketTimeline=(hook,formdata)=>{
  return(dispatch,getState)=>{
    wApi({hook:hook,formdata:formdata}).then(response=>{
      dispatch({
        type:ActionTypes.USER_SUPPORT_TICKET_TIMELINE,
        payload:response
      })
    }).catch(err=>{})
  }
}



/*  USER_SUPPORT_TICKET_VIEW ******************************************/
export const userSupportTicketView=(hook,formdata)=>{
  return(dispatch,getState)=>{
    wApi({hook:hook,formdata:formdata}).then(response=>{
      dispatch({
        type:ActionTypes.USER_SUPPORT_TICKET_VIEW,
        payload:response
      })
    }).catch(err=>{})
  }
}


/* USER_SUPPORT_TICKET_INSERT ******************************************/
export const userSupportTicketInsert=(hook,formdata)=>{
  return(dispatch,getState)=>{
    wApi({hook:hook,formdata:formdata}).then(response=>{
      dispatch({
        type:ActionTypes.USER_SUPPORT_TICKET_INSERT,
        payload:response
      })
    }).catch(err=>{})
  }
}



/* VIEW_EMPLOYEE_LIST ******************************************/
export const viewEmployeeList=(hook,formdata)=>{
  return(dispatch,getState)=>{
    wApi({hook:hook,formdata:formdata}).then(response=>{
      dispatch({
        type:ActionTypes.VIEW_EMPLOYEE_LIST,
        payload:response
      })
    }).catch(err=>{})
  }
}



/* USER REQUEST OTP ******************************************/
export const adminDashboardTaskList=(hook,formdata)=>{
  return(dispatch,getState)=>{
    wApi({hook:hook,formdata:formdata}).then(response=>{
      dispatch({
        type:ActionTypes.ADMIN_DASHBOARD_TASK_LIST,
        payload:response
      })
    }).catch(err=>{})
  }
}

/* USER REQUEST OTP ******************************************/
export const userUpdatePassword=(hook,formdata)=>{
  return(dispatch,getState)=>{
    wApi({hook:hook,formdata:formdata}).then(response=>{
      dispatch({
        type:ActionTypes.USER_UPDATE_PASSWORD,
        payload:response
      })
    }).catch(err=>{})
  }
}


/* USER REQUEST OTP ******************************************/
export const userRequestOTP=(hook,formdata)=>{
  return(dispatch,getState)=>{
    wApi({hook:hook,formdata:formdata}).then(response=>{
      dispatch({
        type:ActionTypes.USER_REQUEST_OTP,
        payload:response
      })
    }).catch(err=>{})
  }
}


/******************************************  TASK UPDATE ******************************************/
export const taskUpdate=(hook,formdata)=>{
  return(dispatch,getState)=>{
    //console.log("action data " + JSON.stringify(formdata))
    wApi({hook:hook,formdata:formdata}).then(response=>{
      dispatch({
        type:ActionTypes.TASK_UPDATE,
        payload:response
      })
     //console.log("getfun")
     //console.log(response)
    }).catch(err=>{})
  }
}

/******************************************  REPORT Login VIEW ******************************************/
export const reportLoginDetailsView=(hook,formdata)=>{
  return(dispatch,getState)=>{
    wApi({hook:hook,formdata:formdata}).then(response=>{
      dispatch({
        type:ActionTypes.REPORT_LOGIN_DETAIL_VIEW,
        payload:response
      })
     //console.log("getfun")
     //console.log(response)
    }).catch(err=>{})
  }
}


/******************************************  REPORT ASSIGN TASK VIEW ******************************************/
export const reportAssignTaskView=(hook,formdata)=>{
  return(dispatch,getState)=>{
    //console.log("action data " + JSON.stringify(formdata))
    wApi({hook:hook,formdata:formdata}).then(response=>{
      dispatch({
        type:ActionTypes.REPORT_ASSIGN_TASK_VIEW,
        payload:response
      })
     //console.log("getfun")
     //console.log(response)
    }).catch(err=>{})
  }
}



/******************************************  Admin Report ******************************************/
export const reportTask=(hook,formdata)=>{
  return(dispatch,getState)=>{
    //console.log("action data " + JSON.stringify(formdata))
    wApi({hook:hook,formdata:formdata}).then(response=>{
      dispatch({
        type:ActionTypes.REPORT_TASK,
        payload:response
      })
     //console.log("getfun")
     //console.log(response)
    }).catch(err=>{})
  }
}

export const reportAssignedTask=(hook,formdata)=>{
  return(dispatch,getState)=>{
    //console.log("action data " + JSON.stringify(formdata))
    wApi({hook:hook,formdata:formdata}).then(response=>{
      dispatch({
        type:ActionTypes.REPORT_ASSIGNED_TASK,
        payload:response
      })
     //console.log("getfun")
     //console.log(response)
    }).catch(err=>{})
  }
}

export const reportMyPendingTask=(hook,formdata)=>{
  return(dispatch,getState)=>{
    //console.log("action data " + JSON.stringify(formdata))
    wApi({hook:hook,formdata:formdata}).then(response=>{
      dispatch({
        type:ActionTypes.REPORT_MY_PENDING_TASK,
        payload:response
      })
     //console.log("getfun")
     //console.log(response)
    }).catch(err=>{})
  }
}


/************************************* Sidebar Menu / Header Data(Notification)  ****************************/


export const viewSidebarMenu=(hook,formdata)=>{
  return(dispatch,getState)=>{
    //console.log("action data " + JSON.stringify(formdata))
    wApi({hook:hook,formdata:formdata}).then(response=>{
      dispatch({
        type:ActionTypes.SIDEBAR_MENU,
        payload:response
      })
     //console.log("getfun")
     //console.log(response)
    }).catch(err=>{})
  }
}


export const viewProfile=(hook,formdata)=>{
  return(dispatch,getState)=>{
    wApi({hook:hook,formdata:formdata}).then(response=>{
      dispatch({
        type:ActionTypes.VIEW_PROFILE,
        payload:response
      })
     //console.log("getfun")
     //console.log(response)
    }).catch(err=>{})
  }
}

/*************************************Login Check****************************************************************/

export const loginCheck=(hook,formdata)=>{
  return(dispatch,getState)=>{
    wApi({hook:hook,formdata:formdata}).then(response=>{
      dispatch({
        type:ActionTypes.LOGIN_CHECK,
        payload:response
      })
     //console.log("getfun")
     //console.log(response)
    }).catch(err=>{})
  }
}



/***********************************View Dashboard****************************************************************/

export const viewDashboardDetail=(hook,formdata)=>{
  return(dispatch,getState)=>{
    wApi({hook:hook,formdata:formdata}).then(response=>{
      dispatch({
        type:ActionTypes.VIEW_DASHBOARD,
        payload:response
      })
     //console.log("getfun")
     //console.log(response)
    }).catch(err=>{})
  }
}


/***********************************View Task Timeline**********************************************************/
export const viewTaskTimeline=(hook,formdata)=>{
  return(dispatch,getState)=>{
    wApi({hook:hook,formdata:formdata}).then(response=>{
      dispatch({
        type:ActionTypes.TASK_TIMELINE_VIEW,
        payload:response
      })
     //console.log("getfun")
     //console.log(response)
    }).catch(err=>{})
  }
}

export const viewReceivedTaskList=(hook,formdata)=>{
  return(dispatch,getState)=>{
    wApi({hook:hook,formdata:formdata}).then(response=>{
      dispatch({
        type:ActionTypes.VIEW_RECEIVED_TASK_LIST,
        payload:response
      })
     //console.log("getfun")
     //console.log(response)
    }).catch(err=>{})
  }
}

export const viewTaskList=(hook,formdata)=>{
  return(dispatch,getState)=>{
    wApi({hook:hook,formdata:formdata}).then(response=>{
      dispatch({
        type:ActionTypes.VIEW_TASK_LIST,
        payload:response
      })
     //console.log("getfun")
     //console.log(response)
    }).catch(err=>{})
  }
}

/****************************View Table*******************************************************/

export const viewCompanyDetail=(hook,formdata)=>{
  return(dispatch,getState)=>{
    wApi({hook:hook,formdata:formdata}).then(response=>{
      dispatch({
        type:ActionTypes.COMPANY_DETAIL,
        payload:response
      })
     //console.log("getfun")
     //console.log(response)
    }).catch(err=>{})
  }
}

export const viewDivisionDetail=(hook,formdata)=>{
  return(dispatch,getState)=>{
    wApi({hook:hook,formdata:formdata}).then(response=>{
      dispatch({
        type:ActionTypes.DIVISION_DETAIL,
        payload:response
      })
     //console.log("getfun")
     //console.log(response)
    }).catch(err=>{})
  }
}


export const viewDepartmentDetail=(hook,formdata)=>{
  return(dispatch,getState)=>{
    wApi({hook:hook,formdata:formdata}).then(response=>{
      dispatch({
        type:ActionTypes.DEPARTMENT_DETAIL,
        payload:response
      })
     //console.log("getfun")
     //console.log(response)
    }).catch(err=>{})
  }
}

export const viewDesignationDetail=(hook,formdata)=>{
  return(dispatch,getState)=>{
    wApi({hook:hook,formdata:formdata}).then(response=>{
      dispatch({
        type:ActionTypes.DESIGNATION_DETAIL,
        payload:response
      })
     //console.log("getfun")
     //console.log(response)
    }).catch(err=>{})
  }
}

export const viewEmployeeDetail=(hook,formdata)=>{
  return(dispatch,getState)=>{
    wApi({hook:hook,formdata:formdata}).then(response=>{
      dispatch({
        type:ActionTypes.EMPLOYEE_DETAIL,
        payload:response
      })
     //console.log("getfun")
     //console.log(response)
    }).catch(err=>{})
  }
}

export const viewEmployee=(hook,formdata)=>{
  return(dispatch,getState)=>{
    wApi({hook:hook,formdata:formdata}).then(response=>{
      dispatch({
        type:ActionTypes.EMPLOYEE_LIST_VIEW,
        payload:response
      })
     //console.log("getfun")
     //console.log(response)
    }).catch(err=>{})
  }
}

export const viewDesignation=(hook,formdata)=>{
  return(dispatch,getState)=>{
    wApi({hook:hook,formdata:formdata}).then(response=>{
      dispatch({
        type:ActionTypes.DESIGNATION_LIST_VIEW,
        payload:response
      })
     //console.log("getfun")
     //console.log(response)
    }).catch(err=>{})
  }
}

export const viewDepartment=(hook,formdata)=>{
  return(dispatch,getState)=>{
    wApi({hook:hook,formdata:formdata}).then(response=>{
      dispatch({
        type:ActionTypes.DEPARTMENT_LIST_VIEW,
        payload:response
      })
     //console.log("getfun")
     //console.log(response)
    }).catch(err=>{})
  }
}

export const viewDivision=(hook,formdata)=>{
  return(dispatch,getState)=>{
    wApi({hook:hook,formdata:formdata}).then(response=>{
      dispatch({
        type:ActionTypes.DIVISION_LIST_VIEW,
        payload:response
      })
     //console.log("getfun")
     //console.log(response)
    }).catch(err=>{})
  }
}

export const viewCompany=(hook,formdata)=>{
  return(dispatch,getState)=>{
    wApi({hook:hook,formdata:formdata}).then(response=>{
      dispatch({
        type:ActionTypes.COMPANY_LIST_VIEW,
        payload:response
      })
     //console.log("getfun")
     //console.log(response)
    }).catch(err=>{})
  }
}



/****************************Delete Code*************************************************/

export const deleteCompanyDetail=(hook,formdata)=>{
  return(dispatch,getState)=>{
    wApi({hook:hook,formdata:formdata}).then(response=>{
      dispatch({
        type:ActionTypes.DELETE_COMPANY_DETAIL,
        payload:response
      })
     //console.log("getfun")
     //console.log(response)
    }).catch(err=>{})
  }
}

export const deleteDivisionDetail=(hook,formdata)=>{
  return(dispatch,getState)=>{
    wApi({hook:hook,formdata:formdata}).then(response=>{
      dispatch({
        type:ActionTypes.DELETE_DIVISION_DETAIL,
        payload:response
      })
     //console.log("getfun")
     //console.log(response)
    }).catch(err=>{})
  }
}

export const deleteDepartmentDetail=(hook,formdata)=>{
  return(dispatch,getState)=>{
    wApi({hook:hook,formdata:formdata}).then(response=>{
      dispatch({
        type:ActionTypes.DELETE_DEPARTMENT_DETAIL,
        payload:response
      })
     //console.log("getfun")
     //console.log(response)
    }).catch(err=>{})
  }
}

export const deleteDesignationDetail=(hook,formdata)=>{
  return(dispatch,getState)=>{
    wApi({hook:hook,formdata:formdata}).then(response=>{
      dispatch({
        type:ActionTypes.DELETE_DESIGNATION_DETAIL,
        payload:response
      })
     //console.log("getfun")
     //console.log(response)
    }).catch(err=>{})
  }
}

export const deleteEmployeeDetail=(hook,formdata)=>{
  return(dispatch,getState)=>{
    wApi({hook:hook,formdata:formdata}).then(response=>{
      dispatch({
        type:ActionTypes.DELETE_EMPLOYEE_DETAIL,
        payload:response
      })
     //console.log("getfun")
     //console.log(response)
    }).catch(err=>{})
  }
}




/****************************Update Code*************************************************/

export const updateCompanyDetail=(hook,formdata)=>{
  return(dispatch,getState)=>{
    wApi({hook:hook,formdata:formdata}).then(response=>{
      dispatch({
        type:ActionTypes.COMPANY_DETAIL_UPDATE,
        payload:response
      })
     //console.log("getfun")
     //console.log(response)
    }).catch(err=>{})
  }
}


export const updateDivisionDetail=(hook,formdata)=>{
  return(dispatch,getState)=>{
    wApi({hook:hook,formdata:formdata}).then(response=>{
      dispatch({
        type:ActionTypes.DIVISION_DETAIL_UPDATE,
        payload:response
      })
     //console.log("getfun")
     //console.log(response)
    }).catch(err=>{})
  }
}

export const updateDepartmentDetail=(hook,formdata)=>{
  return(dispatch,getState)=>{
    wApi({hook:hook,formdata:formdata}).then(response=>{
      dispatch({
        type:ActionTypes.DEPARTMENT_DETAIL_UPDATE,
        payload:response
      })
     //console.log("getfun")
     //console.log(response)
    }).catch(err=>{})
  }
}

export const updateDesignationDetail=(hook,formdata)=>{
  return(dispatch,getState)=>{
    wApi({hook:hook,formdata:formdata}).then(response=>{
      dispatch({
        type:ActionTypes.DESIGNATION_DETAIL_UPDATE,
        payload:response
      })
     //console.log("getfun")
     //console.log(response)
    }).catch(err=>{})
  }
}

export const updateEmployeeDetail=(hook,formdata)=>{
  return(dispatch,getState)=>{
    wApi({hook:hook,formdata:formdata}).then(response=>{
      dispatch({
        type:ActionTypes.EMPLOYEE_DETAIL_UPDATE,
        payload:response
      })
     //console.log("getfun")
     //console.log(response)
    }).catch(err=>{})
  }
}


/****************************Insert Code*************************************************/

export const insertTaskFeedback=(hook,formdata)=>{
  return(dispatch,getState)=>{
    //console.log("action data " + JSON.stringify(formdata))
    wApi({hook:hook,formdata:formdata}).then(response=>{
      dispatch({
        type:ActionTypes.INSERT_TASK_FEEDBACK,
        payload:response
      })
     //console.log("getfun")
     //console.log(response)
    }).catch(err=>{})
  }
}

export const insertNewTask=(hook,formdata)=>{
  return(dispatch,getState)=>{
    wApi({hook:hook,formdata:formdata}).then(response=>{
      dispatch({
        type:ActionTypes.INSERT_NEW_TASK,
        payload:response
      })
     //console.log("getfun")
    // console.log(response)
    }).catch(err=>{})
  }
}

export const insertEmployee=(hook,formdata)=>{
  return(dispatch,getState)=>{
    wApi({hook:hook,formdata:formdata}).then(response=>{
      dispatch({
        type:ActionTypes.INSERT_EMPLOYEE,
        payload:response
      })
     //console.log("getfun")
    // console.log(response)
    }).catch(err=>{})
  }
}

export const insertDesignation=(hook,formdata)=>{
  return(dispatch,getState)=>{
    wApi({hook:hook,formdata:formdata}).then(response=>{
      dispatch({
        type:ActionTypes.INSERT_DESIGNATION,
        payload:response
      })
     //console.log("getfun")
    // console.log(response)
    }).catch(err=>{})
  }
}

export const insertDepartment=(hook,formdata)=>{
  return(dispatch,getState)=>{
    wApi({hook:hook,formdata:formdata}).then(response=>{
      dispatch({
        type:ActionTypes.INSERT_DEPARTMENT,
        payload:response
      })
     //console.log("getfun")
    // console.log(response)
    }).catch(err=>{})
  }
}


export const insertDivision=(hook,formdata)=>{
  return(dispatch,getState)=>{
    wApi({hook:hook,formdata:formdata}).then(response=>{
      dispatch({
        type:ActionTypes.INSERT_DIVISION,
        payload:response
      })
     //console.log("getfun")
    // console.log(response)
    }).catch(err=>{})
  }
}

export const insertCompany=(hook,formdata)=>{
  return(dispatch,getState)=>{
    wApi({hook:hook,formdata:formdata}).then(response=>{
      dispatch({
        type:ActionTypes.INSERT_COMPANY,
        payload:response
      })
     //console.log("getfun")
    // console.log(response)
    }).catch(err=>{})
  }
}

/****************************Drop Down Code*************************************************/

export const getEnumDesignation=(hook,formdata)=>{
  return(dispatch,getState)=>{
    wApi({hook:hook,formdata:formdata}).then(response=>{
      dispatch({
        type:ActionTypes.DESIGNATION_LIST,
        payload:response
      })
     //console.log("getfun")
    // console.log(response)
    }).catch(err=>{})
    
  }
}

export const getEnumDepartment=(hook,formdata)=>{
  return(dispatch,getState)=>{
    wApi({hook:hook,formdata:formdata}).then(response=>{
      dispatch({
        type:ActionTypes.DEPARTMENT_LIST,
        payload:response
      })
     //console.log("getfun")
    // console.log(response)
    }).catch(err=>{})
    
  }
}

export const getEnumDivision=(hook,formdata)=>{
  return(dispatch,getState)=>{
    wApi({hook:hook,formdata:formdata}).then(response=>{
      dispatch({
        type:ActionTypes.DIVISION_LIST,
        payload:response
      })
     //console.log("getfun")
     //console.log(response)
    }).catch(err=>{})
    
  }
}
export const getEnum=(hook,formdata)=>{
  return(dispatch,getState)=>{
    wApi({hook:hook,formdata:formdata}).then(response=>{
      dispatch({
        type:ActionTypes.COMPANY_LIST,
        payload:response
      })
     // console.log(response)
    }).catch(err=>{})
    
  }
}
//---------------------------------------- Form

export const getForm_TaskCreate=(hook,formdata)=>{
  //console.log("getform")
   return(dispatch,getState)=>{
    wApi({hook:hook,formdata:formdata}).then(response=>{
      dispatch({
        type:ActionTypes.FORM_TASK_CREATE,
        payload:response
      })
     // console.log(response)
    }).catch(err=>{})
    
  }
}


export const getForm_EmployeeAdd=(hook,formdata)=>{
  //console.log("getform")
  return(dispatch,getState)=>{
    wApi({hook:hook,formdata:formdata}).then(response=>{
      dispatch({
        type:ActionTypes.FORM_EMPLOYEE_ADD,
        payload:response
      })
     // console.log(response)
    }).catch(err=>{})
    
  }
}

export const getForm_CompanyAdd=(hook,formdata)=>{
  //console.log("getform")
  return(dispatch,getState)=>{
    wApi({hook:hook,formdata:formdata}).then(response=>{
      dispatch({
        type:ActionTypes.FORM_COMPANY_ADD,
        payload:response
      })
     // console.log(response)
    }).catch(err=>{})
    
  }
}

export const getForm_DivisionAdd=(hook,formdata)=>{
  //console.log("getform")
  return(dispatch,getState)=>{
    wApi({hook:hook,formdata:formdata}).then(response=>{
      dispatch({
        type:ActionTypes.FORM_DIVISION_ADD,
        payload:response
      })
     // console.log(response)
    }).catch(err=>{})
    
  }
}

export const getForm_DepartmentAdd=(hook,formdata)=>{
  //console.log("getform")
  return(dispatch,getState)=>{
    wApi({hook:hook,formdata:formdata}).then(response=>{
      dispatch({
        type:ActionTypes.FORM_DEPARTMENT_ADD,
        payload:response
      })
     // console.log(response)
    }).catch(err=>{})
  }
}

export const getForm_DesignationAdd=(hook,formdata)=>{
  //console.log("getform")
  return(dispatch,getState)=>{
    wApi({hook:hook,formdata:formdata}).then(response=>{
      dispatch({
        type:ActionTypes.FORM_DESIGNATION_ADD,
        payload:response
      })
     // console.log(response)
    }).catch(err=>{})
  }
}

  // dispatch({type:ActionTypes.SHOW_PHONE_SIGNUP_FORM_LOADING})   
     // alert(JSON.stringify(response.isconfirm)) 
       // if(response.isconfirm=="YES" && response.ispasswordset!=""){ jumpTo(2);}