import * as ActionTypes from 'actions/ActionTypes'
const defaultState={
    TYPE:"",
    FORM_EMPLOYEE_ADD:{},
    FORM_COMPANY_ADD:{},
    FORM_DIVISION_ADD:{},
    FORM_DEPARTMENT_ADD:{},
    FORM_DESIGNATION_ADD:{},
    INSERT_COMPANY:{},
    INSERT_DIVISION:{},
    INSERT_DEPARTMENT:{},
    INSERT_DESIGNATION:{},
    INSERT_EMPLOYEE:{},
    COMPANY_LIST_VIEW:{},
    DIVISION_LIST_VIEW:{},
    DEPARTMENT_LIST_VIEW:{},
    DESIGNATION_LIST_VIEW:{},
    EMPLOYEE_LIST_VIEW:{},
    FORM_TASK_CREATE:{},
    TASK_TIMELINE_VIEW:{},
    INSERTED_TASK_ID:0,
    INSERTED_COMPANY_ID:0,
    INSERTED_DEPARTMENT:{},
    VIEW_TASK_LIST:{},
    VIEW_RECEIVED_TASK_LIST:{},
    VIEW_DASHBOARD:{},
    LOGIN_CHECK:{},
    SIDEBAR_MENU:{},
    INSERT_TASK_FEEDBACK:{},
    REPORT_MY_PENDING_TASK:{},
    REPORT_ASSIGNED_TAKS:{},
    VIEW_PROFILE:{},
    REPORT_TASK:{},
    EMPLOYEE_DETAIL:{},
    EMPLOYEE_DETAIL_UPDATE:{},
    DESIGNATION_DETAIL:{},
    DESIGNATION_DETAIL_UPDATE:{},
    DEPARTMENT_DETAIL:{},
    DEPARTMENT_DETAIL_UPDATE:{},
    DIVISION_DETAIL:{},
    DIVISION_DETAIL_UPDATE:{},
    COMPANY_DETAIL:{},
    COMPANY_DETAIL_UPDATE:{},
    DELETE_COMPANY_DETAIL:{},
    DELETE_DIVISION_DETAIL:{},
    DELETE_DEPARTMENT_DETAIL:{},
    DELETE_DESIGNATION_DETAIL:{},
    DELETE_EMPLOYEE_DETAIL:{},
    TASK_UPDATE:{},
    REPORT_ASSIGN_TASK_VIEW:{},
    USER_REQUEST_OTP:{},
    USER_UPDATE_PASSWORD:{},
    ADMIN_DASHBOARD_TASK_LIST:{},
    VIEW_EMPLOYEE_LIST:{},
    USER_SUPPORT_TICKET_INSERT:{},
    USER_SUPPORT_TICKET_VIEW:{},
    USER_SUPPORT_TICKET_TIMELINE:{},
    ADMIN_SUPPORT_TICKET_LIST:{},
    SEARCH_TASK_DETAIL:{},
    UPDATE_TASK_DETAIL:{},
    UPDATE_USER_LOGIN:{},
    USER_ADMIN_LOGIN_CHECK:{},
    USER_ADMIN_LOGIN:{},
    TASK_DELETE:{},
    REPORT_LOGIN_DETAIL_VIEW:[]
}

export default function base(state = defaultState, action) {
    switch (action.type) {
      case ActionTypes.TASK_DELETE:{
        return{
          ...state,
          TYPE:action.type,
          TASK_DELETE:action.payload
        }
      }
      case ActionTypes.REPORT_LOGIN_DETAIL_VIEW:{
        return{
            ...state,
            TYPE:action.type,
            REPORT_LOGIN_DETAIL_VIEW:action.payload
        }
      }
      case ActionTypes.USER_ADMIN_LOGIN:{
        //console.log("switch formggg");
        return{
            ...state,
            TYPE:action.type,
            USER_ADMIN_LOGIN:{
              'login':action.payload,
              'msg':'Login',
            }
        }
      }
      case ActionTypes.USER_ADMIN_LOGIN_CHECK:{
        //console.log("switch formggg");
         return{
          ...state,
          TYPE:action.type,
          USER_ADMIN_LOGIN_CHECK:action.payload
        }
      }
      case ActionTypes.UPDATE_USER_LOGIN:{
        return{
          ...state,
          TYPE:action.type,
          UPDATE_USER_LOGIN:action.payload
        }
      }
      case ActionTypes.UPDATE_TASK_DETAIL:{
        return{
          ...state,
          TYPE:action.type,
          UPDATE_TASK_DETAIL:action.payload
        }
      }
      case ActionTypes.SEARCH_TASK_DETAIL:{
        return{
          ...state,
          TYPE:action.type,
          SEARCH_TASK_DETAIL:action.payload
        }
      }
      case ActionTypes.ADMIN_SUPPORT_TICKET_LIST:{
        return{
          ...state,
          TYPE:action.type,
          ADMIN_SUPPORT_TICKET_LIST:action.payload
        }
      }
      case ActionTypes.USER_SUPPORT_TICKET_TIMELINE:{
        return{
          ...state,
          TYPE:action.type,
          USER_SUPPORT_TICKET_TIMELINE:action.payload
        }
      }
      case ActionTypes.USER_SUPPORT_TICKET_VIEW:{
        return{
          ...state,
          TYPE:action.type,
          USER_SUPPORT_TICKET_VIEW:action.payload
        }
      }
      case ActionTypes.USER_SUPPORT_TICKET_INSERT:{
        return{
          ...state,
          TYPE:action.type,
          USER_SUPPORT_TICKET_INSERT:action.payload
        }
      }
      case ActionTypes.VIEW_EMPLOYEE_LIST:{
        return{
          ...state,
          TYPE:action.type,
          VIEW_EMPLOYEE_LIST:action.payload
        }
      }
      case ActionTypes.ADMIN_DASHBOARD_TASK_LIST:{
        return{
          ...state,
          TYPE:action.type,
          ADMIN_DASHBOARD_TASK_LIST:action.payload
        }
      }  
      case ActionTypes.USER_UPDATE_PASSWORD:{
        return{
          ...state,
          TYPE:action.type,
          USER_UPDATE_PASSWORD:action.payload
        }
      }  
      case ActionTypes.USER_REQUEST_OTP:{
        return{
          ...state,
          TYPE:action.type,
          USER_REQUEST_OTP:action.payload
        }
      }  
      case ActionTypes.REPORT_ASSIGN_TASK_VIEW:{
        return{
          ...state,
          TYPE:action.type,
          REPORT_ASSIGN_TASK_VIEW:action.payload
        }
      }  
      case ActionTypes.TASK_UPDATE:{
        //console.log("switch formggg");
        return{
          ...state,
          TYPE:action.type,
          TASK_UPDATE:action.payload
        }
      }  
      case ActionTypes.DELETE_EMPLOYEE_DETAIL:{
        //console.log("switch formggg");
        return{
          ...state,
          TYPE:action.type,
          DELETE_EMPLOYEE_DETAIL:action.payload
        }
      }       
      case ActionTypes.DELETE_DESIGNATION_DETAIL:{
        //console.log("switch formggg");
        return{
            ...state,
            TYPE:action.type,
            DELETE_DESIGNATION_DETAIL:action.payload
        }
      }  
      case ActionTypes.DELETE_DEPARTMENT_DETAIL:{
        //console.log("switch formggg");
        return{
            ...state,
            TYPE:action.type,
            DELETE_DEPARTMENT_DETAIL:action.payload
        }
      }  
      case ActionTypes.DELETE_DIVISION_DETAIL:{
        //console.log("switch formggg");
        return{
            ...state,
            TYPE:action.type,
            DELETE_DIVISION_DETAIL:action.payload
        }
      }

      case ActionTypes.DELETE_COMPANY_DETAIL:{
        //console.log("switch formggg");
        return{
            ...state,
            TYPE:action.type,
            DELETE_COMPANY_DETAIL:action.payload
        }
      }

      case ActionTypes.COMPANY_DETAIL_UPDATE:{
        //console.log("switch formggg");
        return{
            ...state,
            TYPE:action.type,
            COMPANY_DETAIL_UPDATE:action.payload
        }
      }

      case ActionTypes.COMPANY_DETAIL:{
        //console.log("switch formggg");
        return{
            ...state,
            TYPE:action.type,
            COMPANY_DETAIL:action.payload
        }
      }
      case ActionTypes.DIVISION_DETAIL_UPDATE:{
        //console.log("switch formggg");
        return{
            ...state,
            TYPE:action.type,
            DIVISION_DETAIL_UPDATE:action.payload
        }
      }
      case ActionTypes.DIVISION_DETAIL:{
        //console.log("switch formggg");
        return{
            ...state,
            TYPE:action.type,
            DIVISION_DETAIL:action.payload
        }
      }
      case ActionTypes.DEPARTMENT_DETAIL_UPDATE:{
        //console.log("switch formggg");
        return{
            ...state,
            TYPE:action.type,
            DEPARTMENT_DETAIL_UPDATE:action.payload
        }
      }
      case ActionTypes.DEPARTMENT_DETAIL:{
        //console.log("switch formggg");
        return{
            ...state,
            TYPE:action.type,
            DEPARTMENT_DETAIL:action.payload
        }
      }
      case ActionTypes.DESIGNATION_DETAIL_UPDATE:{
        //console.log("switch formggg");
        return{
            ...state,
            TYPE:action.type,
            DESIGNATION_DETAIL_UPDATE:action.payload
        }
      }
      case ActionTypes.DESIGNATION_DETAIL:{
        //console.log("switch formggg");
        return{
            ...state,
            TYPE:action.type,
            DESIGNATION_DETAIL:action.payload
        }
      }
      case ActionTypes.EMPLOYEE_DETAIL_UPDATE:{
        //console.log("switch formggg");
        return{
            ...state,
            TYPE:action.type,
            EMPLOYEE_DETAIL_UPDATE:action.payload
        }
      }
      case ActionTypes.EMPLOYEE_DETAIL:{
        //console.log("switch formggg");
        return{
            ...state,
            TYPE:action.type,
            EMPLOYEE_DETAIL:action.payload
        }
      }
      case ActionTypes.REPORT_TASK:{
        //console.log("switch formggg");
        return{
            ...state,
            TYPE:action.type,
            REPORT_TASK:action.payload
        }
      }
      case ActionTypes.REPORT_ASSIGNED_TASK:{
        //console.log("switch formggg");
        return{
            ...state,
            TYPE:action.type,
            REPORT_ASSIGNED_TASK:action.payload
        }
      }
       case ActionTypes.REPORT_MY_PENDING_TASK:{
        //console.log("switch formggg");
        return{
            ...state,
            TYPE:action.type,
            REPORT_MY_PENDING_TASK:action.payload
        }
      }
      case ActionTypes.INSERT_TASK_FEEDBACK:{
        //console.log("switch formggg");
        return{
            ...state,
            TYPE:action.type,
            INSERT_TASK_FEEDBACK:action.payload
        }
      }
        case ActionTypes.SIDEBAR_MENU:{
        //console.log("switch formggg");
        return{
            ...state,
            TYPE:action.type,
            SIDEBAR_MENU:action.payload
        }
      }
      case ActionTypes.VIEW_PROFILE:{
        //console.log("switch formggg");
        return{
            ...state,
            TYPE:action.type,
            VIEW_PROFILE:action.payload
        }
      }
      case ActionTypes.LOGIN_CHECK:{
        //console.log("switch formggg");
        return{
            ...state,
            TYPE:action.type,
            LOGIN_CHECK:action.payload
        }
      }
      case ActionTypes.LOGOUT_CHECK:{
        //console.log("switch formggg");
        return{
            ...state,
            TYPE:action.type,
            LOGIN_CHECK:{
              'login':false,
              'msg':'Logout',
              'user_ID':0
            }
        }
      }

      case ActionTypes.VIEW_DASHBOARD:{
        //console.log("switch formggg");
        return{
            ...state,
            TYPE:action.type,
            VIEW_DASHBOARD:action.payload
        }
      }
      case ActionTypes.INSERT_NEW_TASK:{
        //console.log("switch formggg");
        return{
            ...state,
            TYPE:action.type,
            INSERTED_TASK_ID:action.payload
        }
      }
      case ActionTypes.VIEW_RECEIVED_TASK_LIST:{
        //console.log("switch formggg");
        return{
            ...state,
            TYPE:action.type,
            VIEW_RECEIVED_TASK_LIST:action.payload
        }
      }
      case ActionTypes.VIEW_TASK_LIST:{
        //console.log("switch formggg");
        return{
            ...state,
            TYPE:action.type,
            VIEW_TASK_LIST:action.payload
        }
      }
      case ActionTypes.TASK_TIMELINE_VIEW:{
        //console.log("switch formggg");
        return{
            ...state,
            TYPE:action.type,
            TASK_TIMELINE_VIEW:action.payload
        }
      }
      case ActionTypes.EMPLOYEE_LIST_VIEW:{
          //console.log("switch formggg");
          return{
              ...state,
              TYPE:action.type,
              EMPLOYEE_LIST_VIEW:action.payload
          }
      }
      case ActionTypes.DESIGNATION_LIST_VIEW:{
          //console.log("switch formggg");
          return{
              ...state,
              TYPE:action.type,
              DESIGNATION_LIST_VIEW:action.payload
          }
      }
      case ActionTypes.DEPARTMENT_LIST_VIEW:{
        //console.log("switch formggg");
        return{
            ...state,
            TYPE:action.type,
            DEPARTMENT_LIST_VIEW:action.payload
        }
      }
      case ActionTypes.DIVISION_LIST_VIEW:{
        //console.log("switch formggg");
        return{
            ...state,
            TYPE:action.type,
            DIVISION_LIST_VIEW:action.payload
        }
      }
      case ActionTypes.COMPANY_LIST_VIEW:{
        //console.log("switch formggg");
        return{
            ...state,
            TYPE:action.type,
            COMPANY_LIST_VIEW:action.payload
        }
      }
      case ActionTypes.INSERT_EMPLOYEE:{
        //console.log("switch formggg");
        return{
            ...state,
            TYPE:action.type,
            INSERTED_EMPLOYEE_ID:action.payload
        }
      }
      case ActionTypes.INSERT_DESIGNATION:{
        //console.log("switch formggg");
        return{
            ...state,
            TYPE:action.type,
            INSERTED_DESIGNATION_ID:action.payload
        }
      }
      case ActionTypes.INSERT_DEPARTMENT:{
        //console.log("switch formggg");
        return{
            ...state,
            TYPE:action.type,
            INSERTED_DEPARTMENT_ID:action.payload
        }
      }
      case ActionTypes.INSERT_DIVISION:{
        //console.log("switch formggg");
        return{
            ...state,
            TYPE:action.type,
            INSERTED_DIVISION_ID:action.payload
        }
      }
      case ActionTypes.INSERT_COMPANY:{
        //console.log("switch formggg");
        return{
            ...state,
            TYPE:action.type,
            INSERTED_COMPANY_ID:action.payload
        }
      }
      case ActionTypes.COMPANY_LIST:{
        //console.log("switch formggg");
        return{
            ...state,
            TYPE:action.type,
            COMPANY_LIST:action.payload
        }
      }
      case ActionTypes.DIVISION_LIST:{
        //console.log("switch formggg");
        return{
            ...state,
            TYPE:action.type,
            DIVISION_LIST:action.payload
        }
      }
      case ActionTypes.DEPARTMENT_LIST:{
        //console.log("switch formggg");
        return{
            ...state,
            TYPE:action.type,
            DEPARTMENT_LIST:action.payload
        }
      }
      case ActionTypes.DESIGNATION_LIST:{
        //console.log("switch formggg");
        return{
            ...state,
            TYPE:action.type,
            DESIGNATION_LIST:action.payload
        }
      }
      case ActionTypes.FORM_EMPLOYEE_ADD:{
        //console.log("switch formggg");
        return{
            ...state,
            TYPE:action.type,
            FORM_EMPLOYEE_ADD:action.payload
        }
      }
      case ActionTypes.FORM_COMPANY_ADD:{
        return {
          ...state,
          TYPE:action.type,
          FORM_COMPANY_ADD:action.payload
        }
      }
      case ActionTypes.FORM_DIVISION_ADD:{
        return {
          ...state,
          TYPE:action.type,
          FORM_DIVISION_ADD:action.payload
        }
      }
      case ActionTypes.FORM_DEPARTMENT_ADD:{
        return {
          ...state,
          TYPE:action.type,
          FORM_DEPARTMENT_ADD:action.payload
        }
      }
      case ActionTypes.FORM_DESIGNATION_ADD:{
        return {
          ...state,
          TYPE:action.type,
          FORM_DESIGNATION_ADD:action.payload
        }
      }
      case ActionTypes.FORM_TASK_CREATE:{
        return {
          ...state,
          TYPE:action.type,
          FORM_TASK_CREATE:action.payload
        }
      }
  
      default:
          return state
    }
}